
const initState = () => ({
  customerFinancialSettings: {},
})
export const state = initState
export const mutations = {
  setCustomerFinancialSettings(state, {customerFinancialSettings}) {
    state.customerFinancialSettings = customerFinancialSettings
  },
}
export const getters = {
  getCustomerFinancialSettings(state) {
    return state.customerFinancialSettings
  },
}
export const actions = {
  async getCustomerFinancialSetting({commit}) {
    const customerFinancialSettingResponse = await this.$axios.$get('/customerfinancialsetting')
    console.log('customerFinancialSettingResponse: ', customerFinancialSettingResponse)
    if(customerFinancialSettingResponse){
      if(customerFinancialSettingResponse.hasErrors){
        customerFinancialSettingResponse.errorMessages.map((error, index) =>{
          this.$toast.error(error.value)
        })
      }else{
        console.log('bent van')
        commit('setCustomerFinancialSettings', {customerFinancialSettings: customerFinancialSettingResponse.value})
      }
    }
  },
}
