const initState = () => ({
  userDetails: null,
  availableModules: [],
  usersCustomers: [],
  shippingAddreses: [],
})

export const state = initState

export const getters = {
  getAvailableModules: (state) => () => {
    return state.availableModules
  },
  getUserDetails: (state) => () => {
    return state.userDetails
  },
  getShippingAddresses: (state) => (isDefault = null) => {
    if (isDefault != null) {
      return state.shippingAddreses.find((x) => x.isDefault === isDefault)
    } else {
      return state.shippingAddreses
    }
  },
  getLoggedUserHasMultipleCustomers: (state) => () => {
    return state.usersCustomers && state.usersCustomers.length > 1
  },
  getUserCustomerById: (state) => (customerId) => {
    let customer
    if (state.usersCustomers) {
      customer = state.usersCustomers.find((uc) => uc.id === customerId)
    }
    return customer
  },
}

export const mutations = {
  setUserDetails(state, {userDetails}) {
    state.userDetails = userDetails
  },
  setAvailableModules(state, {availableModules}) {
    state.availableModules = availableModules
  },
  setUsersCustomers(state, {usersCustomers}) {
    if (usersCustomers && usersCustomers.length > 0) {
      state.usersCustomers = usersCustomers
    } else {
      state.usersCustomers = []
    }
  },
  setShippingAddresses(state, {addresses}) {
    state.shippingAddreses = addresses
  },
  reset(state) {
    Object.assign(state, initState())
  },
}

export const actions = {
  async getUserDetails({commit}) {
    const userDetails = await this.$axios.$get('/User/MyProfile')
    commit('setUserDetails', {userDetails})
  },
  async fetchShippinhAddresses({commit}) {
    const addresses = await this.$axios.$get('/User/GetShippingAddresses')
    commit('setShippingAddresses', {addresses})
  },
  async fetchAvailableModules({commit}) {
    const availableModules = await this.$axios.$get('/User/AvailableModules')
    commit('setAvailableModules', {
      availableModules: availableModules.map((element) => {
        return {text: element.name, value: element.id}
      }),
    })
  },
  async fetchUsersCustomers({commit}, accountNumber) {
    const usersCustomers = await this.$axios.$get(
      `/User/user-customers` +
        (accountNumber ? `?accountNumber=${accountNumber}` : '')
    )
    commit('setUsersCustomers', {usersCustomers})
  },
  async fetchUserById({commit}, {user}) {
    return await this.$axios.post('/User/get-by-id', user)
  },
  async saveGeneralInfo({commit}, {generalInfo}) {
    return await this.$axios.$post('/User/GeneralInfo', generalInfo)
  },
  async saveContactInfo({commit}, {contactInfo}) {
    return await this.$axios.$post('/User/ContactInfo', contactInfo)
  },
  async saveBillingInfo({commit}, {billingInfo}) {
    return await this.$axios.$post('/User/BillingInfo', billingInfo)
  },
  async saveCreditCardData({commit}, {creditCardInfo}) {
    return await this.$axios.$post('/User/CreditCardInfo', creditCardInfo)
  },
  async saveShippingInfo({commit}, {shippingInfo}) {
    return await this.$axios.$post('/User/ShippingInfo', shippingInfo)
  },
  async updateAddress({commit}, {addressInfo}) {
    return await this.$axios.$post('User/UpdateShippingAddress', addressInfo)
  },
  async changePassword({commit}, {passwordInfo}) {
    return await this.$axios.$post('/User/ChangePassword', passwordInfo)
  },
  async saveSettingInfo({commit}, {settingInfo}) {
    const formData = new FormData()
    if (settingInfo.img) {
      formData.append(`image`, settingInfo.img, settingInfo.img.name)
    }

    formData.append(`imageChanged`, settingInfo.imageChanged)
    formData.append(`startupModule`, settingInfo.startupModuleID)

    return await this.$axios.$post('/User/SettingInfo', formData)
  },
}
