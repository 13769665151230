
const initState = () => ({
  orders: {
    new: {
      total: 0,
      items: [],
      options: {},
    },
    processing: {
      total: 0,
      items: [],
      options: {},
    },
    closed: {
      total: 0,
      items: [],
      options: {},
    },
    all: {
      total: 0,
      items: [],
      options: {},
    },
  },
  jobNotes: [],
  jobServices: [],
  page: 1,
  totalOrders: 0,
  options: {},
  jobOrderItems: [],
  parentAndChildJobOrderItems: [],
  jobImages: [],
})

export const state = initState

export const getters = {
  getServiceById: (state) => (id) =>
    state.jobServices.find((item) => item.id === id),
}

export const mutations = {
  setJobOrders(state, {orders, type}) {
    state.orders[type].items = orders
  },
  setTotalOrders(state, {totalOrders, type}) {
    state.orders[type].total = totalOrders
  },
  setOptions(state, {options, type}) {
    if (options) state.orders[type].options = options
  },
  setJobServices(state, {jobServices}) {
    state.jobServices = jobServices
  },
  setLoadingData(state, {service}) {
    const xitem = state.jobServices.find((item) => item.id === service.id)
    Object.assign(xitem, {loadingData: true})
  },
  setFinishedLoadingData(state, {service}) {
    const xitem = state.jobServices.find((item) => item.id === service.id)
    Object.assign(xitem, {loadingData: false})
  },
  increasePageIndex(state, {service}) {
    const xitem = state.jobServices.find((item) => item.id === service.id)
    xitem.page++
    // Object.assign(xitem, {page: service.page})
  },
  setJobsOnJobServices(state, {service, jobs}) {
    const xitem = state.jobServices.find((item) => item.id === service.id)
    xitem.jobs.push(...jobs)
    xitem.updateIndex++
    // Object.assign(xitem, service)
  },
  completeServiceLoading(state, {service}) {
    const xitem = state.jobServices.find((item) => item.id === service.id)
    xitem.isLoading = false
    // Object.assign(xitem, service)
  },
  changeVisibility(state, {service, isVisible}) {
    const xitem = state.jobServices.find((item) => item.id === service.id)
    // service.isVisible = isVisible
    // O
    xitem.isVisible = isVisible
    xitem.updateIndex++
    // Object.assign(xitem, service)
    console.log(
      'service change visibility',
      service.serviceTypeName + '-' + isVisible + '-' + xitem.isVisible
    )
  },
  setJobNotes(state, {notes}) {
    state.jobNotes = notes
  },
  setJobOrderItems(state, {jobOrderItems}) {
    state.jobOrderItems = jobOrderItems
  },
  setParentAndChildJobOrderItems(state, {parentAndChildJobOrderItems}) {
    state.parentAndChildJobOrderItems = parentAndChildJobOrderItems
  },
  updateJobItemAttributeFileUrl(state, {jobItemId, attributeId, fileUrl}) {
    state.parentAndChildJobOrderItems.map(pc =>{
      if(pc.customAttributes){
        pc.customAttributes.map(ca =>{
          if(ca.id === attributeId){
            ca.fileUrl = fileUrl
          }
          return ca
        })
      }
      return pc
    })
  },
  setJobImages(state, {jobImages}) {
    state.jobImages = jobImages
  },
  updateNoteCount(state, {jobID})
  {
    let objIndex;
    objIndex = state.orders.new.items.findIndex((obj => obj.jobID === jobID));
    if(objIndex>=0)
      state.orders.new.items[objIndex].noteCount++

    objIndex = state.orders.processing.items.findIndex((obj => obj.jobID === jobID));
    if(objIndex>=0)
      state.orders.processing.items[objIndex].noteCount++

    objIndex = state.orders.closed.items.findIndex((obj => obj.jobID === jobID));
    if(objIndex>=0)
      state.orders.closed.items[objIndex].noteCount++

    objIndex = state.orders.all.items.findIndex((obj => obj.jobID === jobID));
    if(objIndex>=0)
      state.orders.closed.all[objIndex].noteCount++
  }
}

export const actions = {
  async fetchJobServices({commit, state}, {filters}) {
    console.log(filters)
    const result = await this.$axios
      .$get('/JobOrders/job-services?' + filters)
      .then()

    if (result) {
      commit('setJobServices', {jobServices: result})
    }

    return result
  },

  async fetchJobNotes({commit, state}, {jobID}) {
    const result = await this.$axios.$get(`/JobOrders/${jobID}/notes`)

    if (result) {
      commit('setJobNotes', {notes: result})
    }
    return result
  },

  async saveJobNote({commit, state}, {note, image, jobID}) {
    const formData = new FormData()
    formData.append('image', image)
    formData.append('note', note)
    formData.append('jobOrderID', jobID)
    commit('updateNoteCount',{jobID})
    return await this.$axios.$post(`/JobOrders/notes`, formData)

  },
  async saveJobNoteByUser({commit, state}, {note, jobId, userId}){
    return await this.$axios
      .$post(`/JobOrders/job-note-insert`, {
        JobOrderID: jobId,
        Note: note,
        UserId: userId,
      })
  },
  async fetchJobOrders({commit, state}, {filters, type}) {
    const {page, itemsPerPage} = state.orders[type].options
    const filter = filters || ''
    const result = await this.$axios.$get(
      '/JobOrders/job-orders?status=' +
        type +
        '&pageSize=' +
        (itemsPerPage ?? 20) +
        '&pageNo=' +
        (page - 1 ?? 0) +
        filter
    )

    commit('setJobOrders', {orders: result.items ?? [], type})
    commit('setTotalOrders', {
      totalOrders: result.pagingData.itemCount,
      type,
    })

    return result
  },

  async fetchJobOrderItems({commit, state}, {orderId}) {
    return await this.$axios
      .$get('/JobOrders/' + orderId + '/items')
      .then((result) => {
        commit('setJobOrderItems', {jobOrderItems: result})
      })
  },

  async fetchParentAndChildJobOrderItems({commit, state}, {orderId}) {
    return await this.$axios
      .$get('/JobOrders/' + orderId + '/allItems')
      .then((result) => {
        commit('setParentAndChildJobOrderItems', {
          parentAndChildJobOrderItems: result,
        })
      })
  },

  async fetchJobImages({commit, state}, {orderId}) {
    return await this.$axios
      .$get('/JobOrders/' + orderId + '/images')
      .then((result) => {
        commit('setJobImages', {jobImages: result})
      })
  },

  async cancelOrder({commit, state}, {jobID}) {
    return await this.$axios.$post(`/JobOrders/${jobID}/cancel`)
  },

  async reOrderJob({commit, state}, {jobID}) {
    return await this.$axios.$post(`/JobOrders/re-order/${jobID}`)
  },

  async approveOrder({commit, state}, {jobID, serviceType}) {
    commit('updateNoteCount',{jobID})
    return await this.$axios.$post(`/JobOrders/${jobID}/approve?serviceType=${serviceType}`)
  },
  async declineOrder({commit, state}, {jobID, reasonText}) {
    commit('updateNoteCount',{jobID})
    return await this.$axios.$post(`/JobOrders/${jobID}/decline?reasonText=${reasonText}`)
  },
  async getAllItemsByJob({commit, state}, {jobId}){
    return await this.$axios.$get(`/JobItem/job-items-by-job-id/${jobId}`)
  },
  async getFullJob({commit, state}, {jobId}){
    return await this.$axios.$get(`/job-orders-extended/${jobId}/detail`)
  },
}
